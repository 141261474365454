import Cookie from 'js-cookie';

export const setCsrfTokenFromCookie = () => {
  let authenticity_token = Cookie.get('csrftoken');

  if (authenticity_token) {
    let param_meta = document.createElement("meta");
    param_meta.name = 'csrf-param';
    param_meta.content = 'authenticity_token';

    let token_meta = document.createElement("meta");
    token_meta.name = 'csrf-token';
    token_meta.content = authenticity_token;

    document.head.appendChild(param_meta);
    document.head.appendChild(token_meta);
  } else {
    console.error("Authenticity token not found in cookie");
  }
}