import { elementsOnEvent } from "./jquery-alternative";

document.addEventListener("DOMContentLoaded", () =>
  elementsOnEvent(".print-order-confirmation", "click", function (e) {
    e.preventDefault();

    const divName = "printableArea";
    const content = document.getElementById(divName).innerHTML;
    const mywindow = window.open("", "Print", "height=600,width=800");

    mywindow.document.write("<html><head><title>Print</title>");
    mywindow.document.write("</head><body >");
    mywindow.document.write(content);
    mywindow.document.write("</body></html>");

    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    return mywindow.close();
  })
);
