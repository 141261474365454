import { elementsOnEvent, showElements, hideElements } from "../shared/jquery-alternative";

document.addEventListener('DOMContentLoaded', function() {


  let phone_id_for_success;
  if (document.querySelectorAll(".timer-initial").length > 0) {
    for (let timer of $('.timer-initial')) {
      phone_id_for_success = timer.innerText;
      myFunction(phone_id_for_success);
    }
  }


  elementsOnEvent('.add-number', "click", function() { $('select').formSelect()});
  phone_id_for_success = undefined;
  let user_id = undefined;
  elementsOnEvent('.resend-btn', "click", function(e){
    const data =JSON.parse(this.dataset.button);
    const attempt_number=data.attempt;
    const phone_number_id=data.number;
    phone_id_for_success = phone_number_id;
    user_id = document.querySelector('.user-id').id;

    fetch(
      window.location.origin+'/users/' + user_id + '/phones/' + phone_id_for_success + '/resend_verification_token', 
      {
        headers: {"Content-Type": 'application/json'},
        body: JSON.stringify({
          attempt_number
        }),
      }
    ).catch((error) => {
      console.log('error', error);
    })

    myFunction(phone_id_for_success);
  });
});

var myFunction = function(phone_id_for_success) {

  let interval;
  showElements(document.querySelectorAll('#' + phone_id_for_success + 'resend_msg'));
  hideElements(document.querySelectorAll('#' + phone_id_for_success + 'resend1'));
  hideElements(document.querySelectorAll('#' + phone_id_for_success + 'resend2' ));
  let counter = 31;
  interval = setInterval((function() {
    counter--;
    showElements(document.querySelectorAll('#' + phone_id_for_success + 'timer-text'));
    document.querySelector('#' + phone_id_for_success + 'timer-text').text = counter;
    // Display 'counter' wherever you want to display it.
    if (counter === 0) {
      hideElements(document.querySelectorAll('#' + phone_id_for_success + 'timer-text'));
      hideElements(document.querySelectorAll('#' + phone_id_for_success + 'resend_msg'));
      showElements(document.querySelectorAll('#' + phone_id_for_success + 'resend2' ));
      clearInterval(interval);
    }
  }), 1000);
};
