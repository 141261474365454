import { documentOn, showElements, hideElements } from "../../shared/jquery-alternative";

document.addEventListener('DOMContentLoaded', function() {
  Spree.onPayment = function() {
    if (($('#checkout_form_payment')).is('*')) {
      if ($('#existing_cards').is('*')) {
        hideElements(document.querySelectorAll('#payment-methods'));
        hideElements(document.querySelectorAll('#payment-method-fields'));

        documentOn('click', '#use_existing_card_yes', function() {
          hideElements(document.querySelectorAll('#payment-methods'));
          hideElements(document.querySelectorAll('#payment-method-fields'));
          document.querySelectorAll('.existing-cc-radio').forEach(el => el.disabled = false);
        });

        documentOn('click', '#use_existing_card_no', function() {
          showElements(document.querySelectorAll('#payment-methods'));
          showElements(document.querySelectorAll('#payment-method-fields'));
          document.querySelectorAll('.existing-cc-radio').forEach(el => el.disabled = true);
        });
      }


      $(".cardNumber").payment('formatCardNumber');
      $(".cardExpiry").payment('formatCardExpiry');
      $(".cardCode").payment('formatCardCVC');

      documentOn('change', '.cardNumber', function() {
        $(this).parent().siblings(".ccType").val($.payment.cardType(this.value));
      });

      documentOn('click', 'input[type="radio"][name="order[payments_attributes][][payment_method_id]"]', function() {
        hideElements(document.querySelectorAll("#payment-method .p-li"));
        document.querySelectorAll("#payment-method .p-li :input").forEach(el => el.required = false);
        if (this.checked) {
          showElements(document.querySelectorAll(`#payment_method_${this.value}`));
          document.querySelectorAll(`#payment_method_${this.value} :input`).forEach(el => el.required = true);
        }
      });

      documentOn('click', '#cvv_link', function(event) {
        const windowName = 'cvv_info';
        const windowOptions = 'left=20,top=20,width=500,height=500,toolbar=0,resizable=0,scrollbars=1';
        window.open((this.href), windowName, windowOptions);
        event.preventDefault();
      });

      // Activate already checked payment method if form is re-rendered
      // i.e. if user enters invalid data
      $('input[type="radio"]:checked').click();

      documentOn('submit', '#checkout_form_payment', function(event) {
        // Coupon code application may take a number of seconds.
        // Informing the user that this is happening is a good way to indicate some progress to them.
        // In addition to this, if the coupon code FAILS then they don't lose their just-entered payment data.
        const coupon_code_field = document.querySelector('#order_coupon_code');
        const coupon_code = coupon_code_field.value?.trim();
        if (coupon_code !== '') {
          let coupon_status;
          if (document.querySelectorAll("#coupon_status").length === 0) {
            coupon_status = document.createElement("div")
            document.id = 'coupon_status';
            coupon_code_field.parentNode.append(coupon_status);
          } else {
            coupon_status = document.querySelector("#coupon_status");
          }

          const url = Spree.url(Spree.routes.apply_coupon_code(Spree.current_order_id),
            {
              order_token: Spree.current_order_token,
              coupon_code
            }
          );          
          coupon_status.classList.remove(...coupon_status.classList);
          fetch(url, {
            method: "PUT"
          })
          .then(response => response.json().then(data => {
            if (response.ok) {
              coupon_code_field.value = '';
              coupon_status.classList.add("alert-success")
              coupon_status.innerHTML = "Coupon code applied successfully.";
              this.submit();
            } else {
              coupon_status.classList.add("alert-error")
              coupon_status.innerHTML = data.error;
              Spree.enableSave();
              event.preventDefault();
            }
          }));
        }
      });
    }
  };

  Spree.onPayment();
});
