export const getScript = url => new Promise((resolve, reject) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;

  script.onerror = reject;

  script.onload = script.onreadystatechange = function() {
    const loadState = this.readyState;

    if (loadState && loadState !== 'loaded' && loadState !== 'complete') return;

    script.onload = script.onreadystatechange = null;

    resolve();
  };

  document.head.appendChild(script);
});

export function height(el) {
  parseFloat(getComputedStyle(el, null).height.replace("px", ""));
}

export function showElements(elements) {
  elements.forEach(el => el.style.display = '');
}

export function hideElements(elements) {
  elements.forEach(el => el.style.display = 'none');
}

export function toggle(element) {
  if (element.style.display == 'none') {
    element.style.display = '';
  } else {
    element.style.display = 'none';
  }
}

export function offset(el) {
  var rect = el.getBoundingClientRect();

  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft
  };
}

export function multiSelectValues(element) {
  let selected = [];
  Array.from(element.options).forEach(option => {
    if (option.selected) {
      selected.push(option.value);
    }
  });
  return selected;
}

export function addClass(elementSelector, klass) {
  document.querySelectorAll(elementSelector).forEach(el => el.classList.add(klass));
}

export function removeClass(elementSelector, klass) {
  document.querySelectorAll(elementSelector).forEach(el => el.classList.remove(klass));
}

export const documentOn = (eventName, elementSelector, handler) => {
  // documentOn(eventName, elementSelector, handler);
  // console.log("Adding for eventName", eventName, elementSelector);
  document.addEventListener(eventName, function(e) {
    // loop parent nodes from the target to the delegation node
    // console.log("Searching for eventName", eventName, elementSelector);
    for (var target = e.target; target && target != this; target = target.parentNode) {
      if (target.matches(elementSelector)) {
        handler.call(target, e);
        break;
      }
    }
  }, false);
};

export const elementsOnEvent = (elementSelector, eventName, handler) => {
  document.querySelectorAll(elementSelector).forEach(el => {
    el.addEventListener(eventName, handler)
  });
}

export const elementOn = (element, eventName, elementSelector, handler) => {
  // documentOn(eventName, elementSelector, handler);
  // console.log("Adding for eventName", eventName, elementSelector);
  element.addEventListener(eventName, function(e) {
    // loop parent nodes from the target to the delegation node
    // console.log("Searching for eventName", eventName, elementSelector);
    for (var target = e.target; target && target != this; target = target.parentNode) {
      if (target.matches(elementSelector)) {
        handler.call(target, e);
        break;
      }
    }
  }, false);
};

export const elementParents = (el, selector) => {
  const parents = [];
  while ((el = el.parentNode) && el !== document) {
    if (!selector || el.matches(selector)) parents.push(el);
  }
  return parents;
}

export const elementParent = (el, selector) => {
  while ((el = el.parentNode) && el !== document) {
    if (!selector || el.matches(selector)) {
      return el;
    }
  }
  return null;
}

export const toggleClass = (elementSelector, klass) => {
  document.querySelectorAll(elementSelector).forEach(el => {
    toggleElementClass(el, klass)
  })
}

export const toggleElementClass = (el, klass) => {
  if(el.classList.contains(klass)) {
    el.classList.remove(klass)
  } else {
    el.classList.add(klass)
  }
}