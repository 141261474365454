import { loadSelect2 } from '../shared/load-dynamic';
import { elementsOnEvent } from './jquery-alternative';

document.addEventListener('DOMContentLoaded', function() {
  if (document.getElementById("search_in_products")) {
    document.getElementById("search_in_products").addEventListener("click", (event) => {
      event.preventDefault();
      const form = document.querySelector(document.querySelector(event.target).dataset.form);
      let search_type = event.target.value;
      const form_for_submission = $("<form>").attr({ method: "GET", action: form.action });
      const keyword = document.querySelector("<input>").attr({ value: document.querySelector("input[name='keyword']")?.value, name: "keyword" });
      search_type = $("<input>").attr({ value: search_type, name: "search_type" });
      const commit = $("<input>").attr({ value: "Search", name: "commit" });
      form_for_submission.append(keyword).append(search_type).append(commit);
      form_for_submission.appendTo("body").submit();
      form_for_submission.remove();
    });
  }

  document.querySelector("#form1")?.addEventListener('new-search-suggestions-loaded', () => {
    elementsOnEvent("option[id^='category_']", "click", function(event) {
      event.preventDefault();
      const category_id = this.getAttribute('id').split('_')[1];
      const form = $($(event.target).attr('data-form'));
      let search_type = 'category';
      const form_for_submission = $("<form>").attr({ method: "GET", action: form.attr('action') });
      const keyword = document.querySelector("<input>").attr({ value: $("input[name='keyword']")?.value, name: "keyword" });
      search_type = $("<input>").attr({ value: search_type, name: "search_type" });
      const commit = $("<input>").attr({ value: "Search", name: "commit" });
      const category = $("<input>").attr({ value: category_id, name: "category_id" });
      form_for_submission.append(keyword).append(search_type).append(commit).append(category);
      form_for_submission.appendTo("body").submit();
      form_for_submission.remove();
    })
  });

  if (document.getElementById("search_in_categories")) {
    document.getElementById("search_in_categories").addEventListener("click", (event) => {
      event.preventDefault();
      const form = $($(event.target).attr('data-form'));
      let search_type = $(event.target).attr('value');
      const form_for_submission = $("<form>").attr({ method: "GET", action: form.attr('action') });
      const keyword = document.querySelector("<input>").attr({ value: $("input[name='keyword']")?.value, name: "keyword" });
      search_type = $("<input>").attr({ value: search_type, name: "search_type" });
      const commit = $("<input>").attr({ value: "Search", name: "commit" });
      form_for_submission.append(keyword).append(search_type).append(commit);
      form_for_submission.appendTo("body").submit();
      form_for_submission.remove();
    });
  }

  document.querySelectorAll('.rating-form').forEach(el => {
    $(el).raty({
      score() {
        return this.dataset.score;
      },
      path: '/assets/',
      scoreName: 'review[rating]'
    });
  });

  elementsOnEvent("a.change-price", "click", function() {
    const priceModal = $(this).parent().parent();
    console.log(priceModal);
    priceModal.find('#price-label').addClass('hide');
    priceModal.find('#change-price').removeClass('hide');
  });

  if (document.getElementById("feedback-close-btn")) {
    document.getElementById("feedback-close-btn").addEventListener("click", () => {
      const modal = $(this).parent().parent();
      modal.find('#price-label').removeClass('hide');
      modal.find('#change-price').addClass('hide');
    });
  }

  elementsOnEvent('.line-item-variant-image', 'mouseenter', function() {
    $(this).siblings('.line-item-variant-image1').first().show();
  });

  elementsOnEvent('.line-item-variant-image', 'mouseout', function() {
    $(this).siblings('.line-item-variant-image1').first().hide();
  });


  if (document.querySelector('.searchpage')) {
    loadSelect2().then(() => {
      $('.searchpage').select2({
        ajax: {
          url: '/variants/autocomplete_search_homepage',
          dataType: 'json',
          type: 'GET',
          quietMillis: 50,
          cache: true,
          data(params) {
            return { term: params };
          },
          results(data, params) {
            const globalData = data;
            return {
              results: globalData.map(option => ({
                text: `${ params } in ${ option }`,
                id: option
              }))
            };
          }
        }
      });
    });
  }

  elementsOnEvent("button#save-feedback", "click", function() {
    const modal = this.parentNode.parentNode;
    const number = modal.querySelector('#order-number').textContent;
    const tokenNumber = modal.querySelector('#token-number').textContent;
    const price = modal.querySelector('#final-price').value;
    const comment = modal.querySelector('#feedback-comment').value;
    const rating = modal.querySelector('.rating-form').querySelector('input').value;
    const url = '/api/orders/' + number + '/feedback';
    fetch(url, {
      method: 'POST',
      url,
      body: JSON.stringify({
        comment,
        rating,
        price,
        token: tokenNumber
      })
    }).error(function(error) {
      console.log("Got Error", error)
      alert("Got Error: " + error);
    });
    modal.querySelector('.modal-close').click();
    modal.querySelector('#price-label').classList.remove('hide');
    modal.querySelector('#change-price').classList.add('hide');
    location.reload();
  });
});
