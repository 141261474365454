var processPoints = function(geometry, callback, thisArg) {
  if (geometry instanceof google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    geometry.getArray().forEach(function(g) {
      processPoints(g, callback, thisArg);
    });
  }
};

document.addEventListener('DOMContentLoaded', function() {
  if ((document.querySelector('#delivery_pol')) && !!document.querySelector('#delivery_pol')?.value) {
    
    const geoJsonObject = JSON.parse(document.querySelector('#delivery_pol')?.value);

    const m = document.getElementById('display_map');
    const mapProp = {
      disableDoubleClickZoom: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    const map = new (google.maps.Map)(m, mapProp);
    const bounds = new (google.maps.LatLngBounds);
    map.data.addListener('addfeature', function(e) {
      processPoints(e.feature.getGeometry(), bounds.extend, bounds);
      map.setCenter(bounds.getCenter());
      map.fitBounds(bounds);
    });

    map.data.addGeoJson(geoJsonObject);
    return;
  }
});


