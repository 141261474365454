
import { isElectron, isMobile } from "react-device-detect";

export var notifyJsMessages = function() {
  if (isElectron ||
    !!window.howmuchApi ||
    (!!window.webkit && !!window.webkit.messageHandlers.howmuchApi)
  ) {
    document.querySelectorAll(".js-message").forEach(function(msgElement) {
      if (!!window.howmuchApi) {
        var event = msgElement.getAttribute("data-event");
        if (!!msgElement.getAttribute("data-store")) {
          event = `${ event }|${ msgElement.getAttribute("data-store") }`;
        }
        if (!!msgElement.getAttribute("data-message")) {
          howmuchApi.postMessage(
            event,
            msgElement.getAttribute("data-message")
          );
        } else {
          howmuchApi.postMessage(
            event,
            `${ msgElement.getAttribute("data-error") }${ msgElement.getAttribute(
              "data-warn"
            ) }${ msgElement.getAttribute("data-notice") }`
          );
        }
      } else if (
        !!window.webkit &&
        !!window.webkit.messageHandlers.howmuchApi
      ) {
        var event = msgElement.getAttribute("data-event");
        if (!!msgElement.getAttribute("data-store")) {
          event = `${ event }|${ msgElement.getAttribute("data-store") }`;
        }
        window.webkit.messageHandlers.howmuchApi.postMessage({
          event: event,
          error: msgElement.getAttribute("data-error"),
          warn: msgElement.getAttribute("data-warn"),
          notice: msgElement.getAttribute("data-notice"),
          message: msgElement.getAttribute("data-message"),
          store: msgElement.getAttribute("data-store"),
        });
      } else {
        const event = msgElement.getAttribute("data-event");
        const { ipcRenderer } = window.require("electron");
        if (!!msgElement.getAttribute("data-message")) {
          ipcRenderer.send(event, msgElement.getAttribute("data-message"));
        } else {
          ipcRenderer.send(
            event,
            `${ msgElement.getAttribute("data-store") }${ msgElement.getAttribute(
              "data-error"
            ) }${ msgElement.getAttribute("data-warn") }${ msgElement.getAttribute(
              "data-notice"
            ) }`
          );
        }
        // })
      }
    });
  }
};

export var notifyNative = function(event, params) {
  if (isMobile) {
    if (!!window.howmuchApi) {
      if (!!params && !!params.message) {
        window.howmuchApi.postMessage(event, params.message);
      } else {
        window.howmuchApi.postMessage(event, "JSON");
      }
    } else if (!!window.webkit && !!window.webkit.messageHandlers.howmuchApi) {
      if (!params) {
        window.webkit.messageHandlers.howmuchApi.postMessage({
          event: event,
          message: "JSON",
        });
      } else {
        window.webkit.messageHandlers.howmuchApi.postMessage({
          event: event,
          error: params["data-error"],
          warn: params["data-warn"],
          notice: params["data-notice"],
          message: params["data-message"],
          location: params["data-location"],
        });
      }
    } else if (!!window.originalPostMessage) {
      window.postMessage(event);
    }
  }
};
