import loadGoogleMapsApi from 'load-google-maps-api'
export function showDragMap(mapElement) {
  let lat = mapElement.dataset.lat
  let lng = mapElement.dataset.lng
  let refLat = mapElement.dataset.refLat
  let refLng = mapElement.dataset.refLng
  
  let latElement = mapElement.dataset.latInputSelector ? document.querySelector(mapElement.dataset.latInputSelector) : null
  let lngElement = mapElement.dataset.lntInputSelector ? document.querySelector(mapElement.dataset.lngInputSelector) : null

  let displayLat = mapElement.dataset.latSelector ? document.querySelector(mapElement.dataset.latSelector) : null
  let displayLng = mapElement.dataset.lngSelector ? document.querySelector(mapElement.dataset.lngSelector) : null
  // const latitude = parseFloat(document.getElementById('latitude').textContent)
  // const longitude = parseFloat(document.getElementById('longitude').textContent)
  // const name = document.getElementById('name').textContent
  let latLng = {}
  if(!!lat && !!lng) {
    latLng = { lat: parseFloat(lat), lng: parseFloat(lng) }
  } else if(!!refLat && !!refLng) {
    latLng = { lat: parseFloat(lat), lng: parseFloat(lng) }
  }
  const zoom = parseInt(mapElement.dataset.zoom)

  loadGoogleMapsApi({ key: process.env.GOOGLE_MAPS_API_KEY }).then((googleMaps) => {
    let map = new googleMaps.Map(mapElement, {
      center: latLng,
      zoom: zoom
    });
    let bounds  = new googleMaps.LatLngBounds();
    let loc;

    if(!!refLat && !!refLng) {
      latLng = { lat: parseFloat(refLat), lng: parseFloat(refLng) }
      marker = new googleMaps.Marker({
        position: latLng,
        map: map,
        draggable: false,
        icon: {                             
          url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"                           
        },
        title: 'Store location'
      });
      loc = new googleMaps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(loc);
    }


    if(!!lat && !!lng) {
      latLng = { lat: parseFloat(lat), lng: parseFloat(lng) }
    }
    let marker = new googleMaps.Marker({
      position: latLng,
      map: map,
      draggable: true,
      title: 'Delivery location'
    });
    loc = new googleMaps.LatLng(marker.position.lat(), marker.position.lng());
    bounds.extend(loc);
    marker.addListener('dragend', function(event) {
      if(latElement) latElement.value = event.latLng.lat();
      if(lngElement) lngElement.value = event.latLng.lng();

      if(!!displayLat) displayLat.innerHTML = event.latLng.lat()
      if(!!displayLng) displayLng.innerHTML = event.latLng.lng()

    });

    map.fitBounds(bounds);       // auto-zoom
    map.panToBounds(bounds);     // auto-center    
  });
}

export function showMap(mapElement) {
  if(!!mapElement) {
    let lat = mapElement.dataset.lat
    let lng = mapElement.dataset.lng
    if(!!lat && !!lng) {
      let zoom = parseInt(mapElement.dataset.zoom)
      loadGoogleMapsApi({ key: process.env.GOOGLE_MAPS_API_KEY }).then((googleMaps) => {
        let latLng = { lat: parseFloat(lat), lng: parseFloat(lng) }
        let map = new googleMaps.Map(mapElement, {
          center: latLng,
          zoom: zoom
        });
        let marker = new googleMaps.Marker({
          position: latLng,
          map: map
          // draggable:true,
          // title: 'Pinpoint location'
        });
      })
    }
  }
};
