import { elementsOnEvent, hideElements, toggle } from "../shared/jquery-alternative";


document.addEventListener("DOMContentLoaded", function() {
  elementsOnEvent('legend', "click", function() {
    toggleElementClass(this.parentNode.parentNode, 'active');
    toggleElementClass(this.parentNode.parentNode, 'collapsed');
  });
  if (document.querySelector('input#brands-filter-checkbox:checked')) {
    const element = document.querySelector('input#brands-filter-checkbox:checked');
    toggleElementClass(element.closest('fieldset'), 'active');
    toggleElementClass(element.closest('fieldset'), 'collapsed');
  }
  if (document.querySelector('input#categories-filter-checkbox:checked')) {
    const element = document.querySelector('input#categories-filter-checkbox:checked')
    toggleElementClass(element.closest('fieldset'), 'active');
    toggleElementClass(element.closest('fieldset'), 'collapsed');
  }
  if (document.querySelector('input#show_option_2:checked')) {
    const element = document.querySelector('input#show_option_2:checked')
    toggleElementClass(element.closest('fieldset'), 'active');
    toggleElementClass(element.closest('fieldset'), 'collapsed');
  }
  document.querySelector('#filter-form')?.addEventListener('submit', function(e) {
    const from_val = document.querySelector('#price_from_filter')?.value;
    const to_val = document.querySelector('#price_to_filter')?.value;
    if ((from_val.length === 0) || (to_val.length === 0)) {
      e.preventDefault();
      alert('price filters cannot be empty');
    } else if (isNaN(from_val) || isNaN(to_val)) {
      e.preventDefault();
      alert('Please enter valid price range');
    } else if (parseInt(from_val) > parseInt(to_val)) {
      e.preventDefault();
      alert('Please enter valid price range');
    } else if ((parseInt(from_val) < 0) || (parseInt(to_val) < 0)) {
      e.preventDefault();
      alert('Price values cannot be in negative numbers');
    }
  });
  elementsOnEvent('.btn.show-more', "click", function() {
    toggle(document.querySelector('.opt-box.view-more'));
    if (document.querySelector('#toggle-btn').text === 'View More') {
      document.querySelector('#toggle-btn').innerHTML = 'View Less';
    } else {
      document.querySelector('#toggle-btn').innerHTML = 'View More';
    }
  });
});
document.addEventListener("DOMContentLoaded", function() {
  elementsOnEvent('#slide-submenu', 'click', function(e) {
    $(this).closest('.list-group').fadeOut('slide', function() {
      $('.mini-submenu').fadeIn();
    });
  });
  elementsOnEvent('.mini-submenu', 'click', function() {
    $(this).next('.list-group').toggle('slide');
    hideElements(document.querySelectorAll('.mini-submenu'));
  });
});

document.addEventListener('DOMContentLoaded', function() {
  // $('[data-toggle="tooltip"]').tooltip();
  //TODO fix CollapsibleLists collapsible conflict with materialzie
  if ((document.querySelectorAll(".cart-orders .collapsible").length > 0) && !!$('.cart-orders .collapsible').collapsible) {
    $('.cart-orders .collapsible').collapsible({
      onOpen(el) { // Callback for Collapsible open
        $(el).find('.material-icons.cart-arrow-icon').text('keyboard_arrow_down');
      },
      onClose(el) { // Callback for Collapsible close
        $(el).find('.material-icons.cart-arrow-icon').text('keyboard_arrow_right');
      }
    });
  }
});
