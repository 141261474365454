import loadGoogleMapsApi from 'load-google-maps-api'
let selected_place = undefined;
// const current_country = undefined;


const update_shopping_info = function(selected_place) {
  const current_city = get_selected_city();
  document.querySelector('#shopping-location-info').innerHTML = `You’re shopping in ${current_city}`;
  fetch('/', {
    headers: { 'Content-Type': 'text/html'}, 
    body: JSON.stringify({
      lat: selected_place.geometry.location.lat(),
      lng: selected_place.geometry.location.lng()
    })}).then(response => response.text().then((html) => {
      if (document.querySelectorAll(".geo-preferences").length > 0) {
        document.querySelector('.geo-preferences').replaceChildren();
      }
      document.querySelector('.geo-preferences').innerHTML = html;
      window.initialize_product_store_sliders(true);
    }));
};

var get_selected_city = function(){
  const selected_text = document.querySelector('#location-box')?.querySelector('#city_names')?.querySelector('option:checked')?.text;
  const drop_down_value = document.querySelector('#location-box')?.querySelector('#city_names')?.value;
  const selected_city = selected_text === '' ?  drop_down_value : selected_text;
  return selected_city;
};

const init_input_events = () => $('#area_name').on('input', function(){
  const str = this.value;
  const prefix = get_selected_city()+' ';
  if(str.indexOf(prefix) === 0) {
  } else {
    if (prefix.indexOf(str) >= 0) {
      return this.value = prefix;
    } else {
      return this.value = prefix+str;
    }
  }
});

document.addEventListener('DOMContentLoaded', function() {

  document.querySelector('#submit-shop-location')?.addEventListener('click',function(){
    if (!!selected_place) {
      return window.location = `/shops?lat=${selected_place.geometry.location.lat()}&lng=${selected_place.geometry.location.lng()}&searched_place=${document.querySelector('#area_name')?.value}&selected_city=${document.querySelector('#city_names')?.value}`;
    } else {
      return alert('Please select place');
    }
  });

  document.querySelector('#close-location-box')?.addEventListener('click',() => document.querySelector('#location-box').style.display = 'none');

  document.querySelector('#display-location-box')?.addEventListener('click',() => document.querySelector('#location-box').style.display = 'block');

  loadGoogleMapsApi({ key: process.env.GOOGLE_MAPS_API_KEY, libraries: ['places'] }).then((googleMaps) => {
    const init_auto_complete = function(input, country_iso){
      const auto_complete =  new googleMaps.places.Autocomplete(input, {componentRestrictions:{country: country_iso}});
      auto_complete.setComponentRestrictions({'country': country_iso});
      return auto_complete.addListener('place_changed', function() {
        selected_place = auto_complete.getPlace();
        return update_shopping_info(selected_place);
      });
    };

    if (document.querySelectorAll("#area_name").length > 0) {
      init_auto_complete(document.querySelector('#area_name'), document.querySelector('#area_name').dataset.country);

      return init_input_events();
    }
  })
});
