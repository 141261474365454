import { hideElements, showElements } from "../../shared/jquery-alternative";

document.addEventListener('DOMContentLoaded', function() {
  const getCountryId = region => document.querySelector('#' + region + 'country select')?.value;

  const updateCity = function() {
    const state_id=document.querySelector('#order_bill_address_attributes_state_id')?.value;
    fetch("/api/v2/cities?state_id="+state_id).then(response => response.json().then(data => {
      const select = document.querySelector('#order_bill_address_attributes_city');
      select.innerHTML = '';
      data.cities.forEach(d => {
        let option = document.createElement('option')
        option.value = d
        option.text = d;
        select.append(option)
      });
      if(typeof M !== 'undefined') M.FormSelect([select], {})
    }));
  };

  const update_shipping_form_state = function(order_use_billing) {
    if (order_use_billing?.checked) {
      hideElements(document.querySelectorAll('#shipping .inner'));
      document.querySelectorAll('#shipping .inner input, #shipping .inner select').forEach(el => el.disabled = true);
    } else {
      showElements(document.querySelectorAll('#shipping .inner'));
      document.querySelectorAll('#shipping .inner input, #shipping .inner select').forEach(el => el.disabled = false);
      Spree.updateState('s');
    }
  };

  Spree.updateState = function(region) {
    const countryId = getCountryId(region);
    if (countryId != null) {
      if (Spree.Checkout[countryId] == null) {
        $.get(Spree.routes.states_search, {country_id: countryId}, function(data) {
          Spree.Checkout[countryId] = {
            states: data.states,
            states_required: data.states_required
          };
          Spree.fillStates(Spree.Checkout[countryId], region);
        });
      } else {
        Spree.fillStates(Spree.Checkout[countryId], region);
      }
    }
  };

  Spree.fillStates = function(data, region) {
    const statesRequired = data.states_required;
    const {
      states
    } = data;

    const statePara = ($('#' + region + 'state'));
    const stateSelect = statePara.find('select');
    const stateInput = statePara.find('input');
    const stateSpanRequired = statePara.find('[id$="state-required"]');
    if (states.length > 0) {
      const selected = parseInt(stateSelect.val());
      stateSelect.html('');
      const statesWithBlank = states;
      statesWithBlank.forEach((state) => stateSelect.append($(`<option value='${state.id}'>${state.name}</option>`)));

      stateSelect.prop('disabled', false).show();
      stateInput.hide().prop('disabled', true);
      statePara.show();
      stateSpanRequired.show();
      if (statesRequired) { stateSelect.addClass('required'); }
      stateSelect.removeClass('hide');
      stateInput.removeClass('required');
    } else {
      stateSelect.hide().prop('disabled', true);
      stateInput.show();
      if (statesRequired) {
        stateSpanRequired.show();
        stateInput.addClass('required');
      } else {
        stateInput.val('');
        stateSpanRequired.hide();
        stateInput.removeClass('required');
      }
      statePara.toggle(!!statesRequired);
      stateInput.prop('disabled', !statesRequired);
      stateInput.removeClass('hide');
      stateSelect.removeClass('required');
    }
    if(typeof M !== 'undefined') {
      M.FormSelect(document.querySelectorAll('select'), {})  
    }
    const select2 = document.querySelector('#order_bill_address_attributes_state_id');
    select2.classList.add("hide");
    updateCity();
  };

  Spree.onAddress = function() {
    document.querySelector('#order_bill_address_attributes_state_id')?.addEventListener("change", () => updateCity());

    document.querySelector('#bcountry select')?.addEventListener("change", function() {
      const select2 = document.querySelector('#order_bill_address_attributes_city');
      select2.replaceChildren();
      Spree.updateState('b');
    });

    document.querySelector('#scountry select')?.addEventListener("change", () => Spree.updateState('s'));

    //Spree.updateState 'b'

    const order_use_billing = document.querySelector('input#order_use_billing');
    order_use_billing?.addEventListener("change", function() {
      update_shipping_form_state(order_use_billing);
    });

    update_shipping_form_state(order_use_billing);
  };

  if ($('#checkout_form_address').is('*')) {
    //($ '#checkout_form_address').validate()
    Spree.onAddress();
  }
});
